<template>
  <div id="app">

    <div v-if="loaded">
      <h1>Shopify Matrix Items - Enabled Stores</h1>
      <div class="buttonbar" >
        <div >
          <button @click="cancel">Cancel</button>
          <button v-if="this.modified" @click="save" class="blue">Save</button>
        </div>
        
      </div>
      <div class="flex header">
        <div class="status cell">
          &nbsp;
        </div>
        <div class="sku cell">
          SKU
        </div>
        <div v-for="store in stores" v-bind:key="store.id" class="store cell" :style="{width: storewidth }">
          <span>
            {{store.label}}
          </span>
        </div>
        <div class="apply cell">
          &nbsp;
        </div>
      </div>


      <div class="flex row" v-for="(item, index) in items" v-bind:key="item.sku" :class="{inactive: item.isinactive}">
        <div class="status cell">
          <i class="fa" :class="{ 'fa-circle-thin' : item.saveStatus == 0 && !item.modified , 'fa-check': item.saveStatus == 1, 'fa-times': item.saveStatus == -1, 'fa-circle': item.modified || item.saveStatus == -2 }" ></i>
        </div>
        <div class="sku cell">
          {{item.sku}}
        </div>
        <div v-for="store in stores" v-bind:key="store.id" class="check cell" :style="{width: storewidth }">
          <span>
            <input type="checkbox" :disabled="item.isinactive" :checked="item.stores.includes( store.id )" @change="checkSelect( index, $event.srcElement.checked, store.id )" :value="store.id"/>
          </span>
        </div>
        <div class="apply cell">
          <span>Apply To</span>

          <div class="dropdown" >
            <div class="flex">
              <div>
                <a @click="apply( 'all', item.stores, colour )" class="colour">All</a>
                <br/>
                <a @click="apply( 'colour', item.stores, colour )" v-bind:key="colour" class="colour" v-for="colour in colours">{{colour}}</a>
              </div>
              <div>
                <a @click="apply( 'size', item.stores, size )" v-bind:key="size" class="size" v-for="size in sizes">{{size}}</a>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  
    <div v-else class="loading">
      <div v-if="saving">
        {{saving}}
      </div>
      <div v-else>
        Loading... Please Wait.
      </div>
      
    </div>
  </div>
</template>

<script>

  const axios = require('axios').default;


export default {
  name: 'App',
  components: {
    
  },
  computed: {

    storewidth(){
      return (56 / this.stores.length) + "%";
    }

  },
  mounted(){

    this.load();


  },
  methods: {
    checkSelect( itemIndex, checked, value ){

      if ( !itemIndex ){
        console.log("itemIndex is not defined");
      }
      if ( !checked ){
        console.log("checked is not defined");
      }
      if ( !value ){
        console.log("value is not defined");
      }
      
      if ( checked && !this.items[ itemIndex ].stores.includes( value ) ){

        this.items[ itemIndex ].stores.push( value )

      }else if ( !checked && this.items[ itemIndex ].stores.includes( value ) ){

        this.items[ itemIndex ].stores.forEach( (nsid, i) => {

          if ( nsid == value ){
            delete this.items[ itemIndex ].stores[i];
          }

        } )

      }

      this.modified = true;

      this.items[ itemIndex ].modified = true;

      this.items[ itemIndex ].stores = this.items[ itemIndex ].stores.filter(function (el) {
        return el != null;
      });

    },
    apply( method, stores, val ){

      this.items.forEach( (item, index) => {

        if ( item.isinactive == false ){

          if ( method == "all" ){
          
            this.items[ index ].stores = JSON.parse( JSON.stringify( stores ) );
            this.items[ index ].modified = true;
  
          }else if ( method == "colour" && item.colour == val ){
  
            this.items[ index ].stores = JSON.parse( JSON.stringify( stores ) );
            this.items[ index ].modified = true;
  
          }else if ( method == "size" && item.size == val ){
  
            this.items[ index ].stores = JSON.parse( JSON.stringify( stores ) );
            this.items[ index ].modified = true;
  
          }
        }
        
      } )

    },

    cancel(){
      this.loaded = false;
      window.close();
    },

    save(){

      this.modified = false;

      this.saving = "Saving, Please Wait.";

      let reqCount = 0;
      let resCount = 0;
      let self = this;


      this.items.forEach( (item, index) => {

        this.items[ index ].saveStatus = 0;

        if ( item.modified ){

          reqCount ++;

          axios.post( endpoint + "&method=COMMIT" + "&id=" + id, {items: [item]} ).then(response => {

            if ( response.data.success ){

              this.items[ index ].saveStatus = 1;
            
              this.saving = "Saved.  You can now close this window";

            }else{

              this.items[ index ].saveStatus = -1;

              this.saving = "An error occurred when saving.";

            }

            resCount ++;


          }) .catch( error => {

            this.items[ index ].saveStatus = -1;

            this.saving = "An error occurred when saving.";

            resCount ++;


          })

          this.items[ index ].saveStatus = -2;
          this.items[ index ].modified = 0;


        }

        

      } )


      let check = function(){
        if ( resCount >= reqCount ){

          axios.get( endpoint + "&method=PUSH" + "&id=" + id )

          return true;
        }else{

          setTimeout(check, 1000)  

          return false;
        }
      }


      check();


      

    },

    load(){

      axios.get( endpoint + "&method=LOOKUP" + "&id=" + id ).then(response => {
        
        if ( response.data.success ){

          this.items = response.data.items;
          this.stores = response.data.stores;
          this.colours = response.data.colours;
          this.sizes = response.data.sizes;

          this.loaded = true;


        }

      }) .catch( error => {

        console.log(error);

      })


      if ( 1==-1 ){  // testing only
        this.stores = [
                {
                  label: "Arrow NZ",
                  id: 1
                },
                {
                  label: "Arrow AU",
                  id: 101
                },
                {
                  label: "Outlet NZ",
                  id: 4
                },
                {
                  label: "Outlet AU",
                  id: 5
                }
            ],
      this.colours = ["Navy"],
      this.sizes = ["S", "M", "L"],
      this.items = [
//         {name: "JCP", sku: "JCP : JCP-Black-10XL", colour: "Black", size: "10XL", internalid: "92236", stores: [], isinactive: false},
//         {name: "JCP", sku: "JCP : JCP-Black-3XL", colour: "Black", size: "3XL", internalid: "8830", stores: [], isinactive: true},
//         {name: "JCP", sku: "JCP : JCP-Black-4XL", colour: "Black", size: "4XL", internalid: "92233", stores: [], isinactive: true},
//         {name: "JCP", sku: "JCP : JCP-Navy-10XL", colour: "Navy", size: "10XL", internalid: "92236", stores: [], isinactive: true},
//         {name: "JCP", sku: "JCP : JCP-Navy-3XL", colour: "Navy", size: "3XL", internalid: "8830", stores: [], isinactive: true},
//         {name: "JCP", sku: "JCP : JCP-Navy-4XL", colour: "Navy", size: "4XL", internalid: "92233", stores: [], isinactive: false}
      ]
      this.loaded = true;

      }


    }
    
  },
  data() {
    return {
      stores: [],
      colours: [],
      sizes: [],
      items: [],
      loaded: false,
      saving: null,
      modified: false,
    }
  }
}
</script>

<style>
h1{
  display: inline-block;
  font-size: 22px;
  font-weight: bold;
  color: #4d5f79;
  line-height: 33px;
  vertical-align: top;
  margin-bottom: 4px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
.store{
  text-align:center;
}
.flex{
  display:flex;
}
.check{
  text-align:center;
}
.cell{
  padding:3px 6px;
}
.sku{
  text-align:left;
  width: 30%;
  border-right:#ebebeb 1px solid;
}
.status{
  width:1%;
  color:#dadada;
}
.status .fa-times{
  color:#cc043a;
}
.status .fa-check{
  color:#04cc5e; 
}

.dropdown{
  position:absolute;
  width:180px;
  background:white;
  border:solid 1px gray;
  padding:20px;
  margin-left:-160px;
}
.dropdown .flex > div{
  width:50%;
}
.dropdown a{
  text-decoration: underline;
  color:inherit;
  display:block;
  cursor:pointer;
}

.apply .dropdown{
  display:none;
  width:;
}
.apply.cell {
    width: 10%;
}
.apply span{
  cursor:pointer;
  text-decoration:underline;
  display:none;
}
.row:hover{
  background: #fefeed !important;
}
.row:hover .apply span{
  display:inline;
}
.apply:hover .dropdown{
  display:block;
}
.header{
  background:#e5e5e5;
}

.row:nth-child(odd){
  background:#fafafa;
  border:solid 1px #ebebeb;
}
.inactive{
      color: #b1b1b1;
}
button{
    background: rgb(249,249,249);
    background: linear-gradient(0deg, rgba(249,249,249,1) 0%, rgba(229,229,229,1) 100%);
    font-family: Open Sans,Helvetica,sans-serif;
    appearance: push-button;
    user-select: none;
    white-space: pre;
    align-items: flex-start;
    text-align: center;
    border:#b2b2b2 solid 1px;
    border-radius: 5px;
    font-size: 14px !important;
    font-weight: 600;
    padding:5px 12px !important;
    color:#333333;
    margin:8px;
    cursor:pointer;

}
button.blue{
    background: rgb(73,156,254);
    background: linear-gradient(0deg, rgba(73,156,254,1) 0%, rgba(27,125,243,1) 100%);
    border:#135ab2 solid 1px;
    color:white;

}
button:hover{
  background: #ebebeb;
}
button.blue:hover{
  background: #489bfd;
}
.buttonbar{
  margin:10px -10px;
  height:44px;
}
.loading{
      text-align: center;
    margin-top: calc(100vh - 50%);
    font-size: 24px;
}

</style>
